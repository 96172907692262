@import url(https://fonts.googleapis.com/css?family=Tomorrow:600italic,400,700,600,700italic|Roboto:400|Titillium+Web:400,700,700italic);
*::selection 
{
    background-color:transparent;
} 
*::-moz-selection
{
    background-color:transparent;
}
*
{        
    -webkit-user-select: none;
    /*IE10*/
    -ms-user-select: none;
    user-select: none;

    /*You just need this if you are only concerned with android and not desktop browsers.*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}   
#app { 
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
#main-content {
    flex: 1 1;
}

:root { 
    --black: #000000;
    --boulder: #787878;
    --chenin: #d7d576;
    --crimson: #d91919;
    --granite-gray: #666666;
    --gravel: #4b4b4b;
    --gravel-2: #4b4b4bb5;
    --masala: #3e3a3af7;
    --mist-gray: #c4c4c4;
    --supernova: #fdcf0b;
    --white: #ffffff;
   
    --font-size-l: 16px;
    --font-size-m: 14px;
    --font-size-s: 12.5px;
    --font-size-xl: 21px;
    --font-size-xs: 12px;
    --font-size-xxl: 26px;
    --font-size-xxs: 10px;
   
    --font-family-roboto: "Roboto", Helvetica;
    --font-family-titillium_web: "Titillium Web", Helvetica;
    --font-family-tomorrow: "Tomorrow", Helvetica;
  }

.tomorrow-bold-supernova-26px {
    color: #fdcf0b;
    color: var(--supernova);
    font-family: "Tomorrow", Helvetica;
    font-family: var(--font-family-tomorrow);
    font-size: 26px;
    font-size: var(--font-size-xxl);
    font-style: italic;
    font-weight: 700;
  }

  .tomorrow-bold-white-26px {
    color: #ffffff;
    color: var(--white);
    font-family: "Tomorrow", Helvetica;
    font-family: var(--font-family-tomorrow);
    font-style: italic;
    font-weight: 700;
    text-transform: uppercase;
  }

.welcome-to-the-lucky-snails-racing-club {
    text-shadow: 0 0 1px #fdcf0b, 0 0 1px #fdcf0b, 0 0 1px #fdcf0b, 0 0 1px #fdcf0b;
    letter-spacing: 1.4px;
}

#dot {
    height: 5em;
    width: 5em;
    float: right;
    margin: -1.7em -1em 0 0; 
    border-radius: 50%;
    cursor: pointer;
    will-change: transform;
  }

#footer-parent {
    width:100%;
    background: #666666;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
  }

#button-group{
    justify-content: right;
    color: white;
}

#mce-EMAIL{
    margin-top: 1%;
    width: 195px;
    padding:3% 3% 3% 5%;
    font-size: 12px;
    background: #C4C4C4;
    font-family: "Tomorrow";
    color: black;
}

input::-webkit-input-placeholder{
    color: black;
    font-size: 12px;
}

input:-ms-input-placeholder{
    color: black;
    font-size: 12px;
}

input::placeholder{
    color: black;
    font-size: 12px;
}

#mc_embed_signup_scroll{
    display: flex;
    flex-direction: row;
}

#mce-responses{
    margin-top: 10%;
}

#mc-embedded-subscribe{
    background-color: #0168e1;
    color: #ffffff;
    font-weight: bold;
    width: 115%;
    height: 29px;
    align-self: center;
    padding: 2% 6% 6% 6%;
    margin-left: 6px;
    margin-top: 2px;
    font-size: 12px;
    cursor: pointer;
    font-family: "Tomorrow";
    border-radius: 5px;
}

#mce-EMAIL:focus{
    outline:none;
}

#icon-twitter{
    border-radius: 20px;
    will-change: transform;
}

#icon-twitter:hover{
    background-color: white;
    color: black;
    outline:none !important;
    transition: all ease-in-out 0.3s;
}

#icon-instagram{
    border-radius: 20px;
    will-change: transform;
}

#icon-instagram:hover{
    background-color: white;
    color: black;
    outline:none !important;
    transition: all ease-in-out 0.3s;
}

#icon-discord{
    border-radius: 20px;
    will-change: transform;
}

#icon-discord:hover{
    background-color: white;
    color: black;
    outline:none !important;
    transition: all ease-in-out 0.3s;
}

.subscribe-button{
    background-color: #2D8CFF;
    color: #ffffff;
}

#mint-box{
    height: 6em;
    border-radius: 10px;
    border: none;
    will-change: transform;
}

#mint-grid{
    align-items: center;
    height: 100%;
    padding: 0 5em 0 5em;
    width: 100%;
    will-change: transform;
}

#mint-text{
    font-style: italic;
    font-weight: 1000;
    font-size: 28px;
    color: #000000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    letter-spacing: -0.1em;
    will-change: transform;
}

#mint-button-text{
    font-family: "Tomorrow", Helvetica;
    font-family: var(--font-family-tomorrow);
    width: 60%;
    font-weight: 1000;
    font-size: 18px;
    letter-spacing: -0.1em;
    border-radius: 3em;
    letter-spacing: -.015em;
    will-change: transform;
}

#mint-button-text:focus{
    outline: 0;
}

#mint-container{
    min-width: 100%;
    height: auto;
    padding-top: 1.35em;
    will-change: transform;
}

.racetrack-image-header{
    text-shadow: 0 0 3px #fdcf0b, 0 0 3px #fdcf0b, 0 0 3px #fdcf0b, 0 0 3px #fdcf0b;
    font-family: "Tomorrow", Helvetica;
    font-family: var(--font-family-tomorrow);
    font-style: italic;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 24px;
    color: #000000;
    line-height: 27px;
    letter-spacing: 0.03em;
}

.racetrack-image-text{
    font-family: "Tomorrow", Helvetica;
    font-family: var(--font-family-tomorrow);
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: -0.015em;
}

#social-icons{
    border-radius: 20px;
    height: 2.5em;
    will-change: transform;
}

.tomorrow-font {
    font-family: "Tomorrow";
    font-style: italic;
    font-weight: 700;
    line-height: 28px;
}

.tomorrow-font-600 {
    font-family: "Tomorrow";
    font-style: italic;
    font-weight: 600;
    line-height: 28px;
}

.tomorrow-font-600-descr {
    font-family: "Tomorrow";
    font-style: italic;
    font-weight: 600;
    line-height: 20px;
}

.tomorrow-font-caption {
    font-family: "Tomorrow";
    font-style: italic;
    font-weight: 700;
    line-height: 22px;
    font-size: 14px;
}

.tomorrow-font-footer {
    font-family: "Tomorrow";
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
}

.tomorrow-font-600-none {
    font-family: "Tomorrow";
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
}

.tomorrow-font-header {
    font-family: "Tomorrow";
    font-style: italic;
    font-weight: 700;
    line-height: 40px;
}

.tomorrow-font-navbar {
    font-family: "Tomorrow";
    text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
    cursor: pointer;
    letter-spacing: 0;
    transition: all 0.2s ease;
    width: 91px;
    color:"#FFFFFF";
    font-weight: bold;
    text-transform: uppercase;
}

#mint-timer{
    font-family: "Tomorrow", Helvetica;
    font-family: var(--font-family-tomorrow);
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    border-radius: .5em;
    letter-spacing: 0.05em;
    padding-bottom: 0em;
    will-change: transform;
}   

#mint-timer-mobile{
    font-family: "Tomorrow";
    text-align: center;
    font-weight: bold;
    font-size: 3.5vw;
    border-radius: .5em;
    letter-spacing: 0.05em;
    right: 31.5%;
    top: 42.5%;
    will-change: transform;
}

#mint-timer-mobile-page{
    font-family: "Tomorrow", Helvetica;
    font-family: var(--font-family-tomorrow);
    text-align: center;
    font-weight: bold;
    font-size: 2.5vw;
    border-radius: .5em;
    letter-spacing: 0.05em;
    right: 37%;
    top: 42.5%;
    will-change: transform;
}   

#mint-timer-page{
    font-family: "Tomorrow", Helvetica;
    font-family: var(--font-family-tomorrow);
    text-align: center;
    font-weight: bold;
    font-size: 1.9em;
    border-radius: .5em;
    letter-spacing: 0.05em;
    right: 26%;
    top: 42%;
    will-change: transform;
}   

#countdown-timer{
    color: #fdcf0b;
    color: var(--supernova);
    font-family: "Tomorrow", Helvetica;
    font-family: var(--font-family-tomorrow);
    font-size: 26px;
    font-size: var(--font-size-xxl);
    font-weight: bold;
    color: black;
    text-align: center;
    will-change: transform;
}

@media (max-width:300px){
    #mint-timer-mobile{
        display: none;
    }
  }

@media (max-width: 318px){
    #mint-timer-mobile-page{
        display: none;
    }   
}

@media (max-width:325px){
    #mint-timer-mobile{
        top: 43.5%;
    }
  }

@media (max-width:400px){
    #logo-mobile{
        width: 50%;
    }
    #mint-timer-mobile{
        top: 43.5%;
    }
    #mint-timer-mobile-page{
        margin-right: -10px;
        top: 43% !important;
    } 
    #mint-paragraph{
        text-align: left;
    }
    #styled-header-container{
        font-size: 25px;
    }
  }

  @media (max-width:470px){
    #styled-component{
        padding-left: 1.3em;
        padding-right: 1.3em;
    }
    #mint-timer-mobile-page{
        right: 34% !important;
        left: auto !important;
        top: 42.3% !important;
        font-size: 15px !important; 
    } 
    #mint-timer-mobile{
        right: 33.5% !important;
        top: 42%;
    } 
    #coming-soon{
        font-size: 2em;
    }
    #mint-img-mobile2{
        width: 100% !important;
        padding: 5% !important;
    }
    #mint-img-clicked2{
        width: 1000% !important;
        padding: 5% !important;
    }
  }

@media (max-width:550px){
    #mce-EMAIL {
        width:100%;
    }
    #mint-timer-mobile{
        font-size: 3vw;
        right: 34%;
    }  
    .tomorrow-font {
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
    }    
    .racetrack-image-header{
        font-size: 22px;
    }
    .tomorrow-font-600-none{
        font-size: 12px !important;
        line-height: 21px;
    }
    #styled-header-container{
        font-size: 25px;
    }
    #team-text{
        font-size: 18px !important;
        line-height: 1.8em;
        font-weight: 650;
    }
    #racetrack-images{
        width: 75%;
        padding-bottom: 1em;
        padding-top: 1em;
    }
    #styled-component{
        width: 90% !important;
    }
  }

  @media (max-width:600px){
    #mint-timer-mobile-page{
        right: 36%;
    }   
    #nav-icon{
        right: .5em !important;
        height: 1.4em;
    }
  }

  @media (max-width:641px){
    #team-desktop{
        display: none;
    }
    #team-mobile{
        display: block !important;
    }
    #team-desc-handle{
        font-size: 18px !important;
        width: 80%;
    }
    #team-style-header-container{
        font-size: 24px;
        margin-bottom: 1em;
    }
    #team-logo-mobile{
        display: block !important;
    }
    #team-logo{
        display: none;
    }
    
  }
  
@media (max-width:767px){
    #mint-box{
        display: none;
    }
    #mint-grid{
        height: 50%;
        padding: 1em;
        grid-gap: 1em;
    }
    #mint-container{
        position: absolute;
        width: 100%;
        top: 1em;
        padding-top: 0;
    }
    #home-navbar-display{
        display: none;
    }
    #styled-header-container{
        text-align: center;
    }
    #mint-img-mobile2{
        width: 80%;
        padding: 1em;
    }
    #mint-img-clicked2{
        width: 80%;
        padding: 1em;
    }
    #buy-stack-2-home{
        display: block;
    }
    #buy-long{
        display: none;
    }
    #nav-icon{
        right: 1.5em;
    }
    #mint-helper-text-bottom{
        display: block;
    }
    #icon-desktop{
        display: none;
    }
    #icon-desktop-mobile{
        display: block;
    }
    #gif-license{
        width: 80%  ;
        margin: 8% !important;
    }
    #gif-license-image{
        width: 100%;
        padding-right: 0;
    }
    #welcome-box{
        margin-top: -1.5em;
    }
    #buy-a-snail-grid-2{
        -webkit-columns: 1;
                columns: 1;
        grid-template-columns: 100%;
    }
    #gif-row-box{
        width: 100% !important;
    }

  }

  @media (max-width:865px){
    #dot{
        height: 3em;
        width: 3em;
        margin: -1em -4em 0 0; 
    }
  }


  @media (max-width:900px){
    #team-font{
        font-size: 12px !important;
    }
    #team-font-handle{
        font-size: 9.5px !important;
    }
    #team-desc-handle{
        font-size: 9.5px;
    }
    
  }

  @media (max-width:1024px){
    #mint-button-mobile{
        width: 18%;
        margin-top: -20%;
        margin-right: -13%;
    }
    #logo-mobile{
        width: 40%;
    }
    #home-navbar-display{
        display: none;
    }
    #styled-header-container{
        text-align: center;
    }
    #logo-mobile{
        width: 30%;
    }
    #mint-image{
        min-width: 125px;
        margin-left: 14%
    }
    .racetrack-container{
        direction: column
    }
    #buy-a-snail-grid{
        -webkit-columns: 1;
                columns: 1;
        grid-template-columns: 100%;
    }
    #mint-button-mobile{
        display: none;
    }
    #buy-stack-1{
        display: none;
    }
    #buy-stack-2-page{
        display: block;
    }
    #mint-timer{
        font-size: 18px;
        padding-bottom: .1em;
    }
    #mint-align-mobile{
        min-width: 100%;
    }
  }

    
  @media (min-width:1024px){
    #home-mobile-navbar-display{
        display: none;
    }
    #styled-header-container{
        text-align: left;
    }
  }
  
  @media (min-width:1024px){
    #home-mobile-navbar-display{
        display: none;
    }
    .racetrack-container {
        direction: row
    }
  }

  @media (min-width:1024px){
    #logo-mobile{
        width: 40%;
    }
}

  @media (max-width:1180px){
    #dot{
        margin: -1em -3em 0 0; 
    }

    #styled-component{
        width: 85%;
    }
    #mint-image{
        min-width: 200px;
        margin-left: 7%;
    }
    #mint-button-text{
        padding-left: 5%;
        font-size: 14px;
    }
    #mint-button-mobile{
        width: 18%;
        margin-top: -25%;
        margin-right: -3%;
    }
    #mint-timer{
        font-size: 19px;
        padding-bottom: .1em;
    }
    #mint-timer-page{
        right: 27%;
        top: 42%;
        font-size: 1.6em;
    }    
    #loading{
        font-size: 25px;
    }
    #button-nav{
        font-size: 13.5px;
    }
    #divider{
        width: 80%;
        border-top: 3px solid #FDCF0B;
        box-sizing: border-box;
        margin: -0.65em;
    }
    #media-social-icons{
        right: -1.5em;
        height: 60%;
    }
    #gif-license{
        margin: 0px;
    }
    #gif-row-box{
        width: 95% !important;
    }
    #team-font{
        font-size: 18px;
    }
    #team-font-handle{
        font-size: 14px;
    }
  }

  @media (min-width:1180px){
    #divider{
        width: 988px;
        border-top: 3px solid #FDCF0B;
        box-sizing: border-box;
        margin: -0.65em;
    }
  } 


  @media (max-width:1078px){
    #mint-button-mobile{
        width: 20%;
        margin-top: -32%;
        margin-right: -4%;
    }
    #mint-timer-page{
        right: 26%;
        top: 42%;
        font-size: 1.5em;
    }    
  }

  @media (max-width:1122px){
    #mint-button-mobile{
        width: 20%;
        margin-top: -29%;
        margin-right: -5%;
    }
  }

.nav-active{
    color: #FDCF0B
}
